import {BsChevronLeft, BsChevronRight} from 'react-icons/bs'
import React, { useState } from 'react'
import Pfp from '../assets/founder-pfp.jpeg'

export default function Founder() {

    const [current, setCurrent] = useState(0)
    const [total, setTotal] = useState(3)


    return (
        <div className="section">
            <img className="pfp" align="left" src={Pfp} alt="Atul Mahendroo" />
            Atul Mahendroo founded Solutions Access in the year 2011 and has nurtured the 
            organisation to make it blossom into a respected and reliable name in the 
            executive search space. He has successfully built solutions access to become 
            a valued and trusted partner for their clients, fulfilling their talent 
            needs both within India and globally. Atul has 30 years of rich experience 
            behind him, both in industry as well as executive search. Prior to entering 
            the executive search business, Atul headed sales and marketing function in 
            some of the top business organisations in tyres, consumer goods, and 
            services sectors. He has also worked with a couple of leading global and 
            Indian search and selection firms as head of engineering, automotive, consumer, 
            services and retail practices. He has hands on experience of successfully 
            closing several senior/cxo level mandates for his clients. Coming from sales 
            and marketing background, Atul believes in building strong and lasting 
            relationships which have helped him immensely in developing an exhaustive network 
            of professionals across functions and industries.
            <br /><br />
            Atul is an MBA from IMT, Ghaziabad and holds a bachelors degree in business 
            studies from SS College of business studies, Delhi University. He is passionate 
            about fitness and is also keenly interested in travelling and food.

            <br /><br />

            <strong>Client Testimonials</strong>


            <div className="carousel">
                <div className="left" onClick={() => setCurrent((current + total - 1) % total)}><BsChevronLeft /></div>
                <div className="in">
                    <div className={(current==0) ? "quote active" : "quote"}>
                        <div className="text">
                            I have known Atul as a business partner who has been very focussed 
                            on delivering on time results and have displayed supportive 
                            leadership towards his team. His strong acumen towards business and 
                            customer and his ability to strategically link things to the key 
                            outcome is worth appreciation. Its been good working with him. 
                            Atul brings immense value to those he works with.
                        </div>
                        <div className="author">- Mahua Chakrabarty</div>
                        <div className="desn">CHRO-APAC at Maccaferri</div>
                    </div>
                    <div className={(current==1) ? "quote active" : "quote"}>
                        <div className="text">
                            Atul is known to me for last 5 years, he is an astute Consultant 
                            helping us to close all kinds of challenging positions. he is our 
                            partners in progress and as a person highly reliable, dependable.
                        </div>
                        <div className="author">- Sujan Mukherjee</div>
                        <div className="desn">HR leader for Group Software at Stellantis</div>
                    </div>

                    <div className={(current==2) ? "quote active" : "quote"}>
                        <div className="text">
                            Atul is result oriented professional with an attitude to deliver 
                            the quality services. He is prompt in response and always available 
                            to support in case of any requirement. He is soft spoken but firm 
                            in his views. Having good experience working with him.
                        </div>
                        <div className="author">- Ashutosh Anshu</div>
                        <div className="desn">CHRO at Hitachi India</div>
                    </div>
                </div>

                <div className="right" onClick={() => setCurrent((current + 1) % total)}><BsChevronRight /></div>
                <div className="bottom-container">
                    <div className={(current==0) ? "bottom bottom-active" : "bottom"}></div>
                    <div className={(current==1) ? "bottom bottom-active" : "bottom"}></div>
                    <div className={(current==2) ? "bottom bottom-active" : "bottom"}></div>
                </div>
            </div>
        </div>
    )
}


